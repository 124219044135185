/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { getProjector as r, projectorCache as t, copy3 as o } from "./projectors.js";
function n(n, e, f, l, u, c, i = Math.floor(n.length / 3)) {
  const s = r(e, u, t);
  if (null == s) return !1;
  if (s === o) {
    if (n === l && f === c) return !0;
    const r = f + 3 * i;
    for (let t = f, o = c; t < r; t++, o++) l[o] = n[t];
    return !0;
  }
  const p = f + 3 * i;
  for (let r = f, t = c; r < p; r += 3, t += 3) s(n, r, l, t);
  return !0;
}
export { n as projectBuffer };